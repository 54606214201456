<template>
  <v-container>
    <perseu-card title="Configurações gerais do app">
      <template #content>
        <perseu-input-file-aws
          label="Termo de consentimento"
          :value="consentTerm"
          @input="handleChangeConsentForm"
        >
          <template #additionalInfo>
            <p v-if="lastUpdate">
              Ultima atualização em: {{ lastUpdate | formatDateTime }}
            </p>
            <p v-else>Nenhum arquivo enviado.</p>
          </template>
        </perseu-input-file-aws>
      </template>
    </perseu-card>
  </v-container>
</template>

<script>
import {
  getAllSettings,
  saveConsentFormUrl,
} from "@/services/settings-service";

export default {
  name: "GeneralSettings",
  components: {
    "perseu-input-file-aws": () =>
      import("@/components/shared/PerseuInputFileAWS"),
  },
  data: () => ({
    consentTerm: "",
    lastUpdate: "",
  }),
  mounted() {
    this.loadSettings();
  },
  methods: {
    async loadSettings() {
      const { data } = await getAllSettings();
      this.consentTerm = data?.consentTermUrl;
      this.lastUpdate = data?.updatedAt;
    },
    async handleChangeConsentForm(newUrl) {
      const { data } = await saveConsentFormUrl(newUrl);
      this.consentTerm = data?.consentTermUrl;
      this.lastUpdate = data?.updatedAt;
      this.$toasted.global.defaultSuccess();
    },
  },
};
</script>

<style scoped></style>
