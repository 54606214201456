import api from "./api-service";

const baseUrl = "settings";

export const getAllSettings = () => {
  return api().get(baseUrl);
};

export const saveConsentFormUrl = (newUrl) => {
  return api().post(baseUrl + "/consent-term", {
    consentTermUrl: newUrl,
  });
};
